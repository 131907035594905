define('levit-intranet/mixins/with-socket', ['exports', 'levit-intranet/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    websockets: Ember.inject.service(),
    config: _environment.default,
    socket: null,
    socketName: null,
    socketType: 'user',

    socketRoot: Ember.computed('config', function () {
      var api_host = _environment.default.APP.API_HOST;
      var protocol = 'ws';
      if (Ember.isEmpty(api_host)) {
        var host = window.location.hostname;
        var port = window.location.port;
        var http_protocol = window.location.protocol;
        if (http_protocol === 'https:' || http_protocol === 'https' || http_protocol === 'https://') {
          protocol = 'wss';
        }
        if (Ember.isEmpty(port)) {
          return protocol + '://' + host + '/ws/';
        }
        return protocol + '://' + host + ':' + port + '/ws/';
      }
      return protocol + '://' + api_host + '/ws/';
    }),

    socketUrl: Ember.computed('socketRoot', 'socket', 'socketType', function () {
      return '' + this.get('socketRoot') + this.get('socketName') + '?subscribe-' + this.get('socketType');
    }),

    messageHandler: function messageHandler() {
      // placeholder
    },
    connectHandler: function connectHandler() {
      //placeholder
    },
    closeHandler: function closeHandler() {
      Ember.run.later(this, this.reconnect.bind(this), 500);
    },
    errorHandler: function errorHandler(event) {
      console.error(event.data);
    },
    reconnect: function reconnect() {
      var _this = this;

      var socket = this.get('websockets').socketFor(this.get('socketUrl'));
      Ember.run.later(this, function () {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
          socket.reconnect();
        }
      }, 1500);
    },
    connectSocket: function connectSocket() {
      var socket = this.get('websockets').socketFor(this.get('socketUrl'));
      console.log('socket', socket);
      socket.on('message', this.messageHandler, this);
      socket.on('open', this.connectHandler, this);
      socket.on('close', this.closeHandler, this);
      socket.on('error', this.errorHandler, this);
      this.set('socket', socket);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.connectSocket();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var socket = this.get('socket');
      socket.off('message', this.messageHandler);
      socket.off('error', this.connectHandler);
      socket.off('close', this.closeHandler);
      socket.off('error', this.errorHandler);
      socket.closeSocketFor(this.get('socketUrl'));
      this.set('socket', null);
    }
  });
});