define('levit-intranet/index/app/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      window.opbeat && window._opbeat('setExtraContext', {
        route: 'index/app',
        app: params.app_name
      });
      return params.app_name;
    }
  });
});