define('levit-intranet/models/base/django-celery-beat/crontabschedule', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    minute: (0, _attr.default)('string'),

    hour: (0, _attr.default)('string'),

    day_of_week: (0, _attr.default)('string'),

    day_of_month: (0, _attr.default)('string'),

    month_of_year: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string')

  });
});