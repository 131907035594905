define('levit-intranet/models/base/hosting/contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    ref: (0, _attr.default)('string'),

    last_name: (0, _attr.default)('string'),

    first_name: (0, _attr.default)('string'),

    street: (0, _attr.default)('string'),

    house_num: (0, _attr.default)('string'),

    phone: (0, _attr.default)('string'),

    email: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    owneddomains: (0, _relationships.hasMany)('hosting/domain', {
      async: true,
      inverse: 'owner'
    }),

    manageddomains: (0, _relationships.hasMany)('hosting/domain', {
      async: true,
      inverse: 'on_site'
    }),

    provider: (0, _relationships.belongsTo)('hosting/registrar', {
      async: true,
      inverse: 'registeredcontacts'
    }),

    country: (0, _relationships.belongsTo)('cities_light/country', {
      async: true,
      inverse: null
    }),

    zip: (0, _relationships.belongsTo)('intranet/postalcode', {
      async: true,
      inverse: null
    }),

    contact: (0, _relationships.belongsTo)('crm/contact', {
      async: true,
      inverse: 'providercontacts'
    })

  });
});