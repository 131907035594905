define('levit-intranet/components/form-foreignkey', ['exports', 'ember-cli-crudities/components/form-foreignkey'], function (exports, _formForeignkey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formForeignkey.default;
    }
  });
});