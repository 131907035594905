define('levit-intranet/helpers/evaluate-condition', ['exports', 'ember-cli-crudities/helpers/evaluate-condition'], function (exports, _evaluateCondition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _evaluateCondition.default;
    }
  });
  Object.defineProperty(exports, 'evaluateCondition', {
    enumerable: true,
    get: function () {
      return _evaluateCondition.evaluateCondition;
    }
  });
});