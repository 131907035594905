define('levit-intranet/helpers/filter-for-field', ['exports', 'ember-cli-crudities/helpers/filter-for-field'], function (exports, _filterForField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _filterForField.default;
    }
  });
  Object.defineProperty(exports, 'filterForField', {
    enumerable: true,
    get: function () {
      return _filterForField.filterForField;
    }
  });
});