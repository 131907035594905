define('levit-intranet/models/base/crm/event', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    title: (0, _attr.default)('string'),

    created_at: (0, _attr.default)('nullable'),

    last_updated: (0, _attr.default)('nullable'),

    event_type: (0, _attr.default)('string'),

    state: (0, _attr.default)('string'),

    is_public: (0, _attr.default)('boolean'),

    is_billable: (0, _attr.default)('boolean'),

    bill_rate: (0, _attr.default)('number'),

    duration: (0, _attr.default)('duration'),

    body: (0, _attr.default)('string'),

    linked_mechanism_value: (0, _attr.default)('string'),

    do_send: (0, _attr.default)('boolean'),

    has_reminder: (0, _attr.default)('boolean'),

    reminder: (0, _attr.default)('nullable'),

    date: (0, _attr.default)('nullable'),

    display_date: (0, _attr.default)('nullable'),

    link: (0, _attr.default)(),

    replies_count: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    children: (0, _relationships.hasMany)('crm/event', {
      async: true,
      inverse: 'parent'
    }),

    overdue_actions: (0, _relationships.hasMany)('accounting/overdueaction', {
      async: true,
      inverse: 'event'
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'events'
    }),

    created_by: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: null
    }),

    next_assigned_to: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: null
    }),

    next_assigned_role: (0, _relationships.belongsTo)('intranet/role', {
      async: true,
      inverse: null
    }),

    parent: (0, _relationships.belongsTo)('crm/event', {
      async: true,
      inverse: 'children'
    }),

    billing_service: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    }),

    invoice_line: (0, _relationships.belongsTo)('accounting/invoiceline', {
      async: true,
      inverse: 'events'
    })

  });
});