define('levit-intranet/components/cl-headers', ['exports', 'ember-cli-crudities/components/cl-headers'], function (exports, _clHeaders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _clHeaders.default;
    }
  });
});