define('levit-intranet/models/base/sale/serviceprovision', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    duration_left: (0, _attr.default)('duration'),

    dont_refill: (0, _attr.default)('boolean'),

    service: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'provisions'
    })

  });
});