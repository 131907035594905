define('levit-intranet/services/local-loader', ['exports', 'ember-cli-dynamic-model/services/model-loader', 'levit-intranet/data'], function (exports, _modelLoader, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modelLoader.default.extend({
    fetch_app_meta: function fetch_app_meta() {
      if (!Ember.isEmpty(_data.metadata)) {
        return Ember.RSVP.resolve(_data.metadata);
      }
      return this._super();
    },
    do_get_model_meta: function do_get_model_meta(app, model) {
      var data_obj = _data.default[app + '/' + model];
      if (data_obj === undefined) {
        return this._super(app, model);
      }
      var loaded_data = Ember.RSVP.resolve(this.handle_meta(data_obj, app, model));
      this.get('loaded_meta')[app + '/' + model] = loaded_data;
      return loaded_data;
    }
  });
});