define('levit-intranet/models/crm/event', ['exports', 'levit-intranet/models/base/crm/event', 'ember-data/attr', 'ember-data/relationships'], function (exports, _event, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _event.default.extend({
    replies_count: (0, _attr.default)('number'),
    duration: (0, _attr.default)('duration'),

    replies: (0, _relationships.hasMany)('crm/event', {
      async: true,
      inverse: null
    }),

    close: function close() {
      this.set('state', 'closed');
      return this.save();
    }
  });
});