define('levit-intranet/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass('sidebar'), this.toValue(false), this.use('toRight', { duration: 50 }), this.reverse('fade', { duration: 50 }));
    this.transition(this.use('fade', { duration: 150 }), this.debug());
  };
});