define('levit-intranet/components/form-checkbox', ['exports', 'ember-cli-crudities/components/form-checkbox'], function (exports, _formCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formCheckbox.default;
    }
  });
});