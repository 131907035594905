define('levit-intranet/models/base/asterisk/cdr', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    calldate: (0, _attr.default)('nullable'),

    clid: (0, _attr.default)('string'),

    src: (0, _attr.default)('string'),

    dst: (0, _attr.default)('string'),

    duration: (0, _attr.default)('number'),

    billsec: (0, _attr.default)('number'),

    processed_into_event: (0, _attr.default)('boolean'),

    call_from: (0, _attr.default)('string'),

    call_to: (0, _attr.default)('string'),

    time: (0, _attr.default)('string'),

    answered: (0, _attr.default)('string'),

    missed: (0, _attr.default)('string'),

    received: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string')

  });
});