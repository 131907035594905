define('levit-intranet/components/user-timer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function saveModel() {
    var elapsed = this.model.get('elapsed');
    this.model.set('duration_so_far', elapsed);
    this.model.set('last_start', new Date());
    this.model.save();
  }

  exports.default = Ember.Component.extend({

    actions: {
      saveEdit: function saveEdit(widget) {
        widget.validate();
        this.set('model.editing', false);
        this.get('model').save();
      },
      save: function save() {
        var model = this.get('model');
        Ember.run.debounce({ model: model }, saveModel, 1000);
      },
      doNothing: function doNothing() {
        return Ember.RSVP.resolve(true);
      }
    }
  });
});