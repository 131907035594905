define('levit-intranet/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'levit-intranet/sections'], function (exports, _applicationRouteMixin, _sections) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    authenticator: 'authenticator:django',
    model: function model() {
      return _sections.default;
    },

    actions: {
      authenticate: function authenticate(credentials) {
        var session = this.get('session');
        if (credentials.identification && credentials.password) {
          session.authenticate(this.get('authenticator'), credentials).then(function () {
            session.set('loginError', false);
          }, function () {
            session.set('loginError', 'Invalid credentials. Please retry.');
          });
        }
      },
      invalidate: function invalidate() {
        this.get('session').invalidate();
      },
      stopImpersonate: function stopImpersonate() {
        this.get('session').stopImpersonate();
      }
    }
  });
});