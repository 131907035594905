define('levit-intranet/models/base/wizard/accounting/invoices/send', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    email: (0, _attr.default)('string'),

    subject_prefix: (0, _attr.default)('string')

  });
});