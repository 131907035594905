define('levit-intranet/models/base/hosting/domain', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    full_name: (0, _attr.default)('string'),

    state: (0, _attr.default)('string'),

    dns: (0, _attr.default)('boolean'),

    dns_servers: (0, _attr.default)('string'),

    create_date: (0, _attr.default)('nullable'),

    expiration_date: (0, _attr.default)('nullable'),

    notes: (0, _attr.default)('string'),

    plesk_uid: (0, _attr.default)('nullable'),

    registered_by_us: (0, _attr.default)('boolean'),

    email_by_us: (0, _attr.default)('boolean'),

    email_servers: (0, _attr.default)('string'),

    hosting_by_us: (0, _attr.default)('boolean'),

    www_server: (0, _attr.default)('string'),

    billed_to: (0, _attr.default)('string'),

    reseller: (0, _attr.default)('string'),

    main_party: (0, _attr.default)('string'),

    has_subscription: (0, _attr.default)('string'),

    wrong_number_of_hosts: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    registrar: (0, _relationships.belongsTo)('hosting/registrar', {
      async: true,
      inverse: 'domains'
    }),

    owner: (0, _relationships.belongsTo)('hosting/contact', {
      async: true,
      inverse: 'owneddomains'
    }),

    on_site: (0, _relationships.belongsTo)('hosting/contact', {
      async: true,
      inverse: 'manageddomains'
    }),

    subscription: (0, _relationships.belongsTo)('sale/subscription', {
      async: true,
      inverse: 'domain'
    }),

    alias_of: (0, _relationships.belongsTo)('hosting/domain', {
      async: true,
      inverse: null
    }),

    ordered_by: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'ordereddomains'
    }),

    final_owner: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'domains'
    }),

    hosts: (0, _relationships.hasMany)('hosting/host', {
      async: true,
      inverse: 'domains'
    })

  });
});