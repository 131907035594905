define('levit-intranet/components/form-week', ['exports', 'ember-cli-crudities/components/form-week'], function (exports, _formWeek) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formWeek.default;
    }
  });
});