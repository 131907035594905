define('levit-intranet/models/accounting/invoice', ['exports', 'levit-intranet/models/base/accounting/invoice', 'ember-data/attr'], function (exports, _invoice, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _invoice.default.extend({
    seen: (0, _attr.default)('boolean'),
    seen_date: (0, _attr.default)('date'),
    show_send: (0, _attr.default)('boolean'),
    show_due_date: (0, _attr.default)('boolean'),
    to_pay_in_cents: (0, _attr.default)('number'),
    can_direct_download: (0, _attr.default)('boolean'),
    can_open: (0, _attr.default)('boolean'),
    can_unopen: (0, _attr.default)('boolean'),

    show_payment_term: Ember.computed('sate', 'invoice_type', function () {
      var type = this.get('invoice_type');
      if (type !== 'ci' && type !== 'sc') {
        return false;
      }
      return this.get('state') === 'draft';
    }),

    onPartyChange: Ember.observer('party', function () {
      var _this = this;

      var party = this.get('party');
      if (Ember.isEmpty(party)) {
        return;
      }
      var pt_promise = this.get('party.payment_term');
      if (pt_promise === undefined) {
        return;
      }
      pt_promise.then(function (pt) {
        if (!Ember.isEmpty(pt)) {
          _this.set('payment_term', pt);
        }
      });
    }),

    isNotDraft: Ember.computed('state', function () {
      return this.get('state') !== 'draft';
    })
  });
});