define('levit-intranet/transforms/json', ['exports', 'ember-data-change-tracker/transforms/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _json.default;
    }
  });
});