define('levit-intranet/helpers/records-aggregate', ['exports', 'ember-cli-crudities/helpers/records-aggregate'], function (exports, _recordsAggregate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _recordsAggregate.default;
    }
  });
  Object.defineProperty(exports, 'recordsAggregate', {
    enumerable: true,
    get: function () {
      return _recordsAggregate.recordsAggregate;
    }
  });
});