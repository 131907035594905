define('levit-intranet/components/form-action', ['exports', 'ember-cli-crudities/components/form-action'], function (exports, _formAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formAction.default;
    }
  });
});