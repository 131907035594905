define('levit-intranet/serializers/hosting/domain', ['exports', 'levit-intranet/application/serializer', 'ember-data/serializers/embedded-records-mixin'], function (exports, _serializer, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      ordered_by: {
        serialize: 'id',
        deserialize: 'records'
      },
      final_owner: {
        serialize: 'id',
        deserialize: 'records'
      },
      registrar: {
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });
});