define('levit-intranet/initializers/stripe', ['exports', 'levit-intranet/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];
    var _config$stripe = _environment.default.stripe,
        stripe = _config$stripe === undefined ? {} : _config$stripe;


    application.register('config:stripe', stripe, { instantiate: false });
    application.inject('service:stripe', 'stripeConfig', 'config:stripe');
  }

  exports.default = {
    name: 'stripe',
    initialize: initialize
  };
});