define('levit-intranet/models/base/hosting/registrar', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    protocol: (0, _attr.default)('string'),

    host: (0, _attr.default)('string'),

    apikey: (0, _attr.default)('string'),

    apisecret: (0, _attr.default)('string'),

    default_for_extensions: (0, _attr.default)('string'),

    notes: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    registeredcontacts: (0, _relationships.hasMany)('hosting/contact', {
      async: true,
      inverse: 'provider'
    }),

    domains: (0, _relationships.hasMany)('hosting/domain', {
      async: true,
      inverse: 'registrar'
    })

  });
});