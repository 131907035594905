define('levit-intranet/models/sale/subscription', ['exports', 'levit-intranet/models/base/sale/subscription', 'ember-data/attr'], function (exports, _subscription, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subscription.default.extend({
    expires_in_days: (0, _attr.default)('number'),
    uninvoiced_this_year: (0, _attr.default)('number'),
    is_active: (0, _attr.default)('boolean')
  });
});