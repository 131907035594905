define('levit-intranet/models/accounting/invoiceline', ['exports', 'levit-intranet/models/base/accounting/invoiceline'], function (exports, _invoiceline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function set_attr(origin, target, props) {
    if (origin) {
      props.forEach(function (prop) {
        var propval = origin.get(prop[0]);
        if (propval !== undefined) {
          target.set(prop[1], propval);
        }
      });
    }
  }

  exports.default = _invoiceline.default.extend({
    update_infos: function update_infos(pricelist) {
      var _this = this;

      var product = this.get('product');
      product.then(function (p) {
        if (p) {
          set_attr(p, _this, [['name', 'description']]);
          if (p.get('default_taxes.length')) {
            _this.set('taxes', p.get('default_taxes'));
          }
          if (pricelist === undefined || pricelist === null) {
            set_attr(p, _this, [['unit_price', 'unit_price']]);
          } else {
            pricelist.get('items').then(function (items) {
              var custom_price = items.filter(function (item) {
                return item.get('product.id') === p.get('id');
              });
              if (custom_price.get('length') > 0) {
                _this.set('unit_price', custom_price.objectAt(0).get('price'));
              } else {
                _this.set('unit_price', parseFloat(p.get('unit_price')) * parseFloat(pricelist.get('mult_by')) + parseFloat(pricelist.get('add')));
              }
            });
          }
          if (p.get('sale_type') === 'r' && !Ember.isEmpty(_this.get('subscription'))) {
            _this.get('subscription').then(function (subscription) {
              if (subscription.get('description') !== p.get('name')) {
                _this.set('description', p.get('name') + ': ' + subscription.get('description'));
              }
            });
          }
        }
      });
    },


    onProductChange: Ember.observer('product', 'invoice.party', function () {
      var _this2 = this;

      var product = this.get('product');
      if (product && product.hasOwnProperty('isFulfilled') && product.get('isFulfilled')) {
        Ember.run.later(function () {
          _this2.get('invoice.party').then(function (party) {
            if (party) {
              party.get('pricelist').then(function (pricelist) {
                _this2.update_infos(pricelist);
              });
            } else {
              _this2.update_infos(null);
            }
          });
        }, 150);
      }
    }),

    allowCN: Ember.computed('line_type', 'invoice.state', 'invoice.invoice_type', function () {
      if (this.get('line_type') !== 'i') {
        return false;
      }
      if (this.get('invoice.state') === 'draft' || this.get('invoice.state') === 'quote') {
        return false;
      }
      var invoiceType = this.get('invoice.invoice_type');
      if (invoiceType !== 'ci' && invoiceType !== 'si') {
        return false;
      }
      return true;
    }),

    showClientFieldset: Ember.computed('line_type', 'invoice.invoice_type', function () {
      if (this.get('line_type') !== 'i') {
        return false;
      }
      var invoiceType = this.get('invoice.invoice_type');
      if (invoiceType !== 'ci' && invoiceType !== 'cc') {
        return false;
      }
      return true;
    })
  });
});