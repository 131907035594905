define('levit-intranet/models/base/helpdesk/quickguide', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    question: (0, _attr.default)('string'),

    answer: (0, _attr.default)('string'),

    position: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    tags: (0, _relationships.hasMany)('intranet/tag', {
      async: true,
      inverse: null
    })

  });
});