define('levit-intranet/models/crm/timer', ['exports', 'levit-intranet/models/base/crm/timer', 'levit-intranet/utils/make-request'], function (exports, _timer, _makeRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _timer.default.extend({
    elapsed: Ember.computed('is_running', 'last_start', 'duration_so_far', function () {
      if (this.get('is_running')) {
        var soFar = this.get('duration_so_far');
        var lastStart = window.moment(this.get('last_start'));
        var now = window.moment(new Date());
        var sinceLastStart = parseInt(now.diff(lastStart, 'seconds'));
        var elapsed = (soFar + sinceLastStart).toString();
        return elapsed.toString();
      }
      return this.get('duration_so_far').toString();
    }),

    getRootURL: function getRootURL(method) {
      var rv = this.get('store').adapterFor('crm/timer').buildURL('crm/timer', this.get('id'));
      if (!Ember.isEmpty(method)) {
        rv += method + '/';
      }
      return rv;
    },
    _do: function _do(method) {
      var _this = this;

      var url = this.getRootURL(method);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _makeRequest.default)(url, {}).then(function (response) {
          response.json().then(function (json) {
            _this.get('store').pushPayload(json);
            resolve();
          }, reject);
        }, reject);
      });
    },
    stop: function stop() {
      if (this.get('is_running')) {
        return this._do('stop');
      }
      return Ember.RSVP.resolve(this);
    },
    start: function start() {
      if (!this.get('is_running')) {
        return this._do('start');
      }
      return Ember.RSVP.resolve(this);
    },
    setEdit: function setEdit() {
      this.set('editing', true);
      return Ember.RSVP.resolve(this);
    }
  });
});