define('levit-intranet/models/base/accounting/account', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    reconcile: (0, _attr.default)('boolean'),

    deferral: (0, _attr.default)('boolean'),

    party_required: (0, _attr.default)('boolean'),

    kind: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    children: (0, _relationships.hasMany)('accounting/account', {
      async: true,
      inverse: 'parent'
    }),

    parent: (0, _relationships.belongsTo)('accounting/account', {
      async: true,
      inverse: 'children'
    })

  });
});