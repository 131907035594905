define('levit-intranet/components/register-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    domains: new Ember.A(),

    fieldsets: [{
      fields: [{
        name: 'reseller',
        label: 'Reseller',
        widget: 'foreignkey',
        class: 'col-sm-6',
        extra: {
          related_model: 'crm/party',
          dontSeed: true
        }
      }, {
        name: 'owner',
        label: 'Owner',
        widget: 'foreignkey',
        class: 'col-sm-6',
        required: true,
        extra: {
          related_model: 'crm/party',
          dontSeed: true
        }
      }, {
        name: 'dns_servers',
        label: 'DNS Servers',
        class: 'col-sm-6',
        required: true,
        extra: {
          help: 'Comma-separated list of IP\'s'
        }
      }, {
        name: 'contact',
        label: 'Contact',
        widget: 'foreignkey',
        class: 'col-sm-6',
        required: true,
        extra: {
          related_model: 'crm/contact',
          property_path: 'owner.contacts',
          autoComplete: false
        }
      }, {
        widget: 'fieldset',
        innerClass: 'col-sm-6',
        fields: [{
          name: 'dont_renew',
          label: 'Don\'t renew',
          class: 'col-sm-6',
          widget: 'checkbox'
        }, {
          name: 'next_invoice_date',
          label: 'Next invoice date',
          class: 'col-sm-6',
          widget: 'date',
          extra: { help: 'leave blank for automatic date' }
        }],
        extra: {
          dont_fetch_meta: true
        }
      }, {
        name: 'domains',
        label: 'Domains',
        widget: 'tomany-table',
        extra: {
          dont_fetch_meta: true,
          preventAdd: true
        },
        fields: [{
          name: 'full_name',
          label: 'Full name',
          required: true
        }, {
          name: 'create_hosting_on',
          label: 'Create hosting on',
          widget: 'foreignkey',
          extra: {
            related_model: 'hosting/host'
          }
        }, {
          name: 'is_alias',
          label: 'Is alias',
          widget: 'checkbox'
        }, {
          name: 'alias_of_name',
          label: 'Alias of',
          extra: {
            display_condition: {
              operator: 'eq',
              property_path: 'is_alias',
              value: true
            }
          }
        }, {
          name: 'product',
          label: 'Product',
          widget: 'foreignkey',
          required: true,
          extra: {
            related_model: 'sale/product',
            query: {
              sale_type: 'r'
            }
          }
        }]
      }]
    }],

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var domains = new Ember.A();
      this.get('data.extensions').forEach(function (extension) {
        domains.pushObject(new Ember.Object({
          full_name: '' + _this.get('data.short_name') + extension
        }));
      });
      this.set('domains', domains);
    }
  });
});