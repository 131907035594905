define('levit-intranet/components/form-tomany-list-detail', ['exports', 'ember-cli-crudities/components/form-tomany-list-detail'], function (exports, _formTomanyListDetail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formTomanyListDetail.default;
    }
  });
});