define('levit-intranet/models/base/crm/address', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    street: (0, _attr.default)('string'),

    is_billing: (0, _attr.default)('boolean'),

    is_active: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'addresses'
    }),

    country: (0, _relationships.belongsTo)('cities_light/country', {
      async: true,
      inverse: null
    }),

    city: (0, _relationships.belongsTo)('intranet/postalcode', {
      async: true,
      inverse: null
    })

  });
});