define('levit-intranet/models/base/crm/party', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    ref: (0, _attr.default)('string'),

    is_customer: (0, _attr.default)('boolean'),

    is_partner: (0, _attr.default)('boolean'),

    is_supplier: (0, _attr.default)('boolean'),

    notes: (0, _attr.default)('string'),

    vat: (0, _attr.default)('string'),

    registration: (0, _attr.default)('string'),

    language: (0, _attr.default)('string'),

    dont_send_invoices_by_email: (0, _attr.default)('boolean'),

    auto_open_invoices: (0, _attr.default)('boolean'),

    attach_invoice: (0, _attr.default)('boolean'),

    primary_email: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: 'party'
    }),

    contactmechanisms: (0, _relationships.hasMany)('crm/partycontactmechanism', {
      async: true,
      inverse: 'party'
    }),

    contacts: (0, _relationships.hasMany)('crm/contact', {
      async: true,
      inverse: 'party'
    }),

    addresses: (0, _relationships.hasMany)('crm/address', {
      async: true,
      inverse: 'party'
    }),

    events: (0, _relationships.hasMany)('crm/event', {
      async: true,
      inverse: 'party'
    }),

    subscriptions: (0, _relationships.hasMany)('sale/subscription', {
      async: true,
      inverse: 'party'
    }),

    provisions: (0, _relationships.hasMany)('sale/serviceprovision', {
      async: true,
      inverse: 'party'
    }),

    invoices: (0, _relationships.hasMany)('accounting/invoice', {
      async: true,
      inverse: 'party'
    }),

    ordereddomains: (0, _relationships.hasMany)('hosting/domain', {
      async: true,
      inverse: 'ordered_by'
    }),

    domains: (0, _relationships.hasMany)('hosting/domain', {
      async: true,
      inverse: 'final_owner'
    }),

    sector: (0, _relationships.belongsTo)('crm/sector', {
      async: true,
      inverse: 'parties'
    }),

    internal_contact: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: 'parties'
    }),

    default_contact: (0, _relationships.belongsTo)('crm/contact', {
      async: true,
      inverse: null
    }),

    country: (0, _relationships.belongsTo)('cities_light/country', {
      async: true,
      inverse: 'parties'
    }),

    pricelist: (0, _relationships.belongsTo)('sale/pricelist', {
      async: true,
      inverse: 'parties'
    }),

    payment_term: (0, _relationships.belongsTo)('accounting/paymentterm', {
      async: true,
      inverse: null
    }),

    segments: (0, _relationships.hasMany)('crm/segment', {
      async: true,
      inverse: 'parties'
    }),

    attachements: (0, _relationships.hasMany)('intranet/attachement', {
      async: true,
      inverse: null
    })

  });
});