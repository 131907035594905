define('levit-intranet/models/base/accounting/overduestep', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    label: (0, _attr.default)('string'),

    delay: (0, _attr.default)('number'),

    date_field: (0, _attr.default)('string'),

    apply_to_unread_invoices_only: (0, _attr.default)('boolean'),

    action_type: (0, _attr.default)('string'),

    action_title_template: (0, _attr.default)('string'),

    recurrent: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    template: (0, _relationships.belongsTo)('intranet/emailtemplate', {
      async: true,
      inverse: null
    }),

    user: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: null
    })

  });
});