define('levit-intranet/components/sliding-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'nav',
    classNameBindings: ['open', 'isAllowed'],

    session: Ember.inject.service(),

    open: false,
    isAllowed: Ember.computed('session.currentUser', function () {
      var group = this.get('session.currentUser.group_name');
      if (Ember.isEmpty(group)) {
        return false;
      }
      return group === 'admin';
    })
  });
});