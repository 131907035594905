define('levit-intranet/models/base/crm/timer', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    duration_so_far: (0, _attr.default)('duration'),

    last_start: (0, _attr.default)('nullable'),

    is_running: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    created_by: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: null
    }),

    event: (0, _relationships.belongsTo)('crm/event', {
      async: true,
      inverse: null
    })

  });
});