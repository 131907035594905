define('levit-intranet/models/base/intranet/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    __str__: (0, _attr.default)('string'),

    username: (0, _attr.default)('string'),

    email: (0, _attr.default)('string'),

    group_name: (0, _attr.default)('string'),

    password1: (0, _attr.default)('string'),

    password2: (0, _attr.default)('string'),

    last_login: (0, _attr.default)('nullable'),

    date_joined: (0, _attr.default)('nullable'),

    language: (0, _attr.default)('string'),

    impersonator: (0, _attr.default)('string'),

    receive_notifications_by_email: (0, _attr.default)('boolean'),

    group: (0, _relationships.belongsTo)('auth/group', {
      async: true,
      inverse: null
    }),

    parties: (0, _relationships.hasMany)('crm/party', {
      async: true,
      inverse: 'internal_contact'
    }),

    contacts: (0, _relationships.hasMany)('crm/contact', {
      async: true,
      inverse: 'internal_contact'
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'user'
    })

  });
});