define('levit-intranet/models/hosting/domain', ['exports', 'levit-intranet/models/base/hosting/domain'], function (exports, _domain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _domain.default.extend({
    previous_expiration: Ember.computed('expiration_date', function () {
      var expiration = this.get('expiration_date');
      if (Ember.isEmpty(expiration)) {
        return expiration;
      }
      expiration = new Date(expiration);
      expiration.setFullYear(expiration.getFullYear() - 1);
      expiration = expiration.toISOString().split('T')[0];
      return expiration;
    })
  });
});