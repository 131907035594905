define('levit-intranet/services/model-loader', ['exports', 'ember-cli-dynamic-model/services/model-loader'], function (exports, _modelLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modelLoader.default;
    }
  });
});