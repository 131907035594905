define('levit-intranet/authenticators/django', ['exports', 'ember-simple-auth/authenticators/base', 'levit-intranet/config/environment', 'levit-intranet/utils/make-request'], function (exports, _base, _environment, _makeRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    offline: Ember.inject.service(),

    init: function init() {
      var globalConfig = _environment.default['ember-simple-auth'] || {};
      this.serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
    },
    authenticate: function authenticate(credentials) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = { username: credentials.identification, password: credentials.password };
        (0, _makeRequest.default)(_this.serverAuthEndpoint + '/login/', data).then(function (response) {
          Ember.run(function () {
            resolve(response);
          });
        }, function (xhr /*, status, error */) {
          Ember.run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },
    restore: function restore(data) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _makeRequest.default)(_this2.serverAuthEndpoint + '/me/', null, 'GET').then(function () /* response */{
          window._opbeat && window._opbeat('setUserContext', {
            username: data.username
          });
          resolve(data);
        }, function () /* xhr , status, error */{
          var offline = _this2.get('offline');
          if (offline.isUp) {
            reject();
            _this2.invalidate();
          } else {
            resolve(data);
          }
        });
      });
    },
    invalidate: function invalidate() /* data */{
      var _this3 = this;

      function success(resolve) {
        resolve();
      }
      window._opbeat && window._opbeat('setUserContext', {
        username: ''
      });
      return new Ember.RSVP.Promise(function (resolve /*, reject */) {
        (0, _makeRequest.default)(_this3.serverAuthEndpoint + '/logout/', {}).then(function () /* response */{
          Ember.run(function () {
            success(resolve);
          });
        }, function () /* xhr, status, error */{
          Ember.run(function () {
            success(resolve);
          });
        });
      });
    },
    makeRequest: function makeRequest() {}
  });
});