define('levit-intranet/models/base/sale/serviceprovisionwithdrawal', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    amount: (0, _attr.default)('duration'),

    __str__: (0, _attr.default)('string'),

    provision: (0, _relationships.belongsTo)('sale/serviceprovision', {
      async: true,
      inverse: 'withdrawals'
    }),

    event: (0, _relationships.belongsTo)('crm/event', {
      async: true,
      inverse: 'withdrawal'
    })

  });
});