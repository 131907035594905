define('levit-intranet/components/dashboard-per-role/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['container-fluid'],

    tetherClass: 'modal-dialog modal-lg',
    tetherAttachment: 'middle center',
    tetherTargetAttachment: 'middle center',
    tetherTarget: 'document.body',

    changed_models: [],

    onModelsChanged: Ember.observer('changed_models', function () {
      var _this = this;

      var changed = this.get('changed_models.length');
      if (changed !== 0) {
        Ember.run.later(this, function () {
          _this.set('changed_models', []);
        }, 150);
      }
    })
  });
});