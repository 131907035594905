define('levit-intranet/components/main-menu/component', ['exports', 'levit-intranet/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    sections: [],
    debug: _environment.default.debug,
    api_host: computed(function () {
      return _environment.default.APP.API_HOST;
    }),
    api_namespace: computed(function () {
      return _environment.default.APP.API_NAMESPACE;
    }),
    django_host: computed(function () {
      return window.location.protocol + '//' + window.location.host.split(':')[0] + ':8000';
    })
  });
});