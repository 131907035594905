define('levit-intranet/models/base/accounting/invoiceline', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    line_type: (0, _attr.default)('string'),

    description: (0, _attr.default)('string'),

    quantity: (0, _attr.default)('number'),

    unit_price: (0, _attr.default)('number'),

    date: (0, _attr.default)('nullable'),

    position: (0, _attr.default)('number'),

    price_includes_taxes: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    events: (0, _relationships.hasMany)('crm/event', {
      async: true,
      inverse: 'invoice_line'
    }),

    invoice: (0, _relationships.belongsTo)('accounting/invoice', {
      async: true,
      inverse: 'lines'
    }),

    product: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    }),

    service_provision: (0, _relationships.belongsTo)('sale/serviceprovision', {
      async: true,
      inverse: null
    }),

    subscription: (0, _relationships.belongsTo)('sale/subscription', {
      async: true,
      inverse: 'invoicelines'
    }),

    taxes: (0, _relationships.hasMany)('accounting/tax', {
      async: true,
      inverse: null
    })

  });
});