define('levit-intranet/models/base/wizard/crm/events/adminreply', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    body: (0, _attr.default)('string'),

    is_public: (0, _attr.default)('boolean'),

    close: (0, _attr.default)('boolean'),

    is_billable: (0, _attr.default)('boolean'),

    bill_rate: (0, _attr.default)('number'),

    duration: (0, _attr.default)('duration'),

    date: (0, _attr.default)('nullable'),

    next_assigned_to: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: null
    }),

    billing_service: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    })

  });
});