define('levit-intranet/-meta/reset/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember-cli-crudities/mixins/change-route'], function (exports, _unauthenticatedRouteMixin, _changeRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_changeRoute.default, _unauthenticatedRouteMixin.default, {
    modelLoader: Ember.inject.service('modelLoader'),

    model: function model() {
      var modelLoader = this.get('modelLoader');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        modelLoader.ensure_model('intranet', 'user').then(function (app_info) {
          resolve(app_info.meta);
        }).catch(function (err) {
          reject(err);
        });
      });
    }
  });
});