define('levit-intranet/models/base/crm/contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    full_name: (0, _attr.default)('string'),

    short_name: (0, _attr.default)('string'),

    pronoun: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    contactmechanisms: (0, _relationships.hasMany)('crm/contactcontactmechanism', {
      async: true,
      inverse: 'contact'
    }),

    providercontacts: (0, _relationships.hasMany)('hosting/contact', {
      async: true,
      inverse: 'contact'
    }),

    internal_contact: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: 'contacts'
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'contacts'
    })

  });
});