define('levit-intranet/models/crm/party', ['exports', 'levit-intranet/models/base/crm/party'], function (exports, _party) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _party.default.extend({
    impersonate: function impersonate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('user').then(function (user) {
          user.impersonate().then(resolve, reject);
        }, reject);
      });
    }
  });
});