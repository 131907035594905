define('levit-intranet/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    siteTitle: Ember.computed(function () {
      return window._main_title;
    })
  });
});