define('levit-intranet/models/base/django-celery-beat/periodictask', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    task: (0, _attr.default)('string'),

    interval: (0, _attr.default)('string'),

    crontab: (0, _attr.default)('string'),

    solar: (0, _attr.default)('string'),

    clocked: (0, _attr.default)('string'),

    args: (0, _attr.default)('string'),

    kwargs: (0, _attr.default)('string'),

    queue: (0, _attr.default)('string'),

    exchange: (0, _attr.default)('string'),

    routing_key: (0, _attr.default)('string'),

    headers: (0, _attr.default)('string'),

    priority: (0, _attr.default)('string'),

    expires: (0, _attr.default)('string'),

    expire_seconds: (0, _attr.default)('string'),

    one_off: (0, _attr.default)('string'),

    start_time: (0, _attr.default)('string'),

    enabled: (0, _attr.default)('string'),

    last_run_at: (0, _attr.default)('string'),

    total_run_count: (0, _attr.default)('string'),

    date_changed: (0, _attr.default)('string'),

    description: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string')

  });
});