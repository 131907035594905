define('levit-intranet/models/base/accounting/invoice', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    create_timestamp: (0, _attr.default)('nullable'),

    date: (0, _attr.default)('nullable'),

    number: (0, _attr.default)('string'),

    invoice_type: (0, _attr.default)('string'),

    ref: (0, _attr.default)('string'),

    state: (0, _attr.default)('string'),

    due_date: (0, _attr.default)('nullable'),

    quote_number: (0, _attr.default)('string'),

    quote_date: (0, _attr.default)('nullable'),

    quote_expiry_date: (0, _attr.default)('nullable'),

    paid_so_far: (0, _attr.default)('number'),

    sent_by_post: (0, _attr.default)('boolean'),

    notes: (0, _attr.default)('string'),

    to_pay: (0, _attr.default)('string'),

    total: (0, _attr.default)('string'),

    taxed_total: (0, _attr.default)('string'),

    is_overdue: (0, _attr.default)('string'),

    seen: (0, _attr.default)('string'),

    seen_date: (0, _attr.default)('string'),

    show_send: (0, _attr.default)('string'),

    show_due_date: (0, _attr.default)('string'),

    to_pay_in_cents: (0, _attr.default)('string'),

    payment_status: (0, _attr.default)('string'),

    party_pays_on_time: (0, _attr.default)('string'),

    paid_date: (0, _attr.default)('string'),

    can_direct_download: (0, _attr.default)('string'),

    can_open: (0, _attr.default)('string'),

    can_unopen: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    lines: (0, _relationships.hasMany)('accounting/invoiceline', {
      async: true,
      inverse: 'invoice'
    }),

    overdue_actions: (0, _relationships.hasMany)('accounting/overdueaction', {
      async: true,
      inverse: 'invoice'
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'invoices'
    }),

    address: (0, _relationships.belongsTo)('crm/address', {
      async: true,
      inverse: null
    }),

    payment_term: (0, _relationships.belongsTo)('accounting/paymentterm', {
      async: true,
      inverse: null
    }),

    account: (0, _relationships.belongsTo)('accounting/account', {
      async: true,
      inverse: null
    }),

    reconciliation: (0, _relationships.belongsTo)('accounting/reconciliation', {
      async: true,
      inverse: null
    }),

    act_as_pay_for: (0, _relationships.belongsTo)('accounting/invoice', {
      async: true,
      inverse: null
    }),

    taxes: (0, _relationships.hasMany)('accounting/tax', {
      async: true,
      inverse: null
    }),

    account_moves: (0, _relationships.hasMany)('accounting/accountmove', {
      async: true,
      inverse: null
    }),

    attachements: (0, _relationships.hasMany)('intranet/attachement', {
      async: true,
      inverse: null
    })

  });
});