define('levit-intranet/models/base/crm/partycontactmechanism', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    mechanism_type: (0, _attr.default)('string'),

    value: (0, _attr.default)('string'),

    slug_value: (0, _attr.default)('string'),

    include_in_mailing: (0, _attr.default)('boolean'),

    has_unsubscribed: (0, _attr.default)('boolean'),

    position: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'contactmechanisms'
    })

  });
});