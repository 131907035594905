define('levit-intranet/models/base/wizard/hosting/domains/associatesubscription', ['exports', 'ember-data/model', 'ember-data/relationships'], function (exports, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    subscription: (0, _relationships.belongsTo)('sale/subscription', {
      async: true,
      inverse: null
    })

  });
});