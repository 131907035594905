define('levit-intranet/mixins/hash-attrs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ready: false,

    init: function init() {
      var _this = this;

      var hash = this.get('attrsHash');
      var meta = this.get('attrsMeta');
      var attrs = {};
      if (!Ember.isEmpty(hash)) {
        Object.keys(hash).forEach(function (key) {
          attrs[key] = hash[key];
        });
      }
      if (!Ember.isEmpty(meta)) {
        meta.forEach(function (item) {
          if (Ember.isArray(item)) {
            attrs[item[0]] = _this.get('meta.' + item[1]);
          } else {
            attrs[item] = _this.get('meta.' + item);
          }
        });
      }
      this.setProperties(attrs);
      this._super();
    }
  });
});