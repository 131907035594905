define('levit-intranet/components/user-preferences/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    fieldsets: [{
      fields: [{
        fields: [{
          name: 'username',
          readonly: true
        }, { name: 'email' }, {
          name: 'language',
          required: true
        }],
        innerClass: 'col-md-4 col-sm-6',
        widget: 'fieldset'
      }],
      title: null
    }]
  });
});