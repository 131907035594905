define('levit-intranet/utils/evaluator', ['exports', 'ember-cli-crudities/utils/evaluator'], function (exports, _evaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _evaluator.default;
    }
  });
});