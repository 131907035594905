define('levit-intranet/index/index/route', ['exports', 'ember-cli-crudities/mixins/change-route'], function (exports, _changeRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_changeRoute.default, {
    get_meta: function get_meta(promise) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        promise.then(function (meta) {
          resolve(meta.meta);
        }, reject);
      });
    },
    get_dashboard: function get_dashboard(promise) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        promise.then(function () {
          resolve(_this.get('store').findAll('intranet/dashboard'));
        }, reject);
      });
    },
    model: function model() {
      window._opbeat && window._opbeat('setExtraContext', {
        route: 'index/index'
      });
      var promises = {
        'dashboard': this.get_dashboard(this._super({ app_name: 'intranet', model_name: 'dashboard' })),
        'events': this.get_meta(this._super({ app_name: 'crm', model_name: 'event' })),
        'events_filters': Ember.RSVP.resolve({ 'state': 'open', 'assigned_to_me': true }),
        'invoices': this.get_meta(this._super({ app_name: 'accounting', model_name: 'invoice' })),
        'invoices_filters': Ember.RSVP.resolve({ 'state': 'draft', 'invoice_type': 'ci' }),
        'invoices_fields': Ember.RSVP.resolve(['party', 'taxed_total']),
        'receivable': this.get_meta(this._super({ app_name: 'accounting', model_name: 'invoice' })),
        'receivable_filters': Ember.RSVP.resolve({ 'unpaid': 2, 'invoice_type': 'ci' }),
        'receivable_fields': Ember.RSVP.resolve(['number', 'party', 'to_pay'])
      };
      return Ember.RSVP.hash(promises);
    }
  });
});