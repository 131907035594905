define('levit-intranet/index/form/route', ['exports', 'ember-cli-crudities/mixins/change-route'], function (exports, _changeRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_changeRoute.default, {
    session: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      window._opbeat && window._opbeat('setExtraContext', {
        route: 'index/form',
        app: params.app_name,
        model: params.model_name,
        id: params.id
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super(params).then(function (model) {
          var user_group = _this.get('session.currentUser.group_name');
          if (user_group === 'client') {
            model.actionsFilters = ['_user_', 'download', 'pay_'];
          } else {
            model.actionsExcludes = ['_user_', 'pay_'];
          }
          resolve(model);
        }, reject);
      });
    }
  });
});