define('levit-intranet/components/navbar-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['navbar', 'navbar-default', 'navbar-fixed-top', 'main-nav', 'material-nav'],
    title: window.title
  });
});