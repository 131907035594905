define('levit-intranet/services/stripe-other', ['exports', 'levit-intranet/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    init: function init() {
      Ember.$.getScript('https://js.stripe.com/v3/').done(function () {
        window.stripe = window.Stripe(_environment.default.stripe.key);
      });
    },
    getBancontact: function getBancontact(id, amount, description, owner) {
      return window.stripe.createSource({
        type: 'bancontact',
        amount: amount,
        currency: 'eur',
        statement_descriptor: description,
        redirect: {
          return_url: '' + this.get('session.currentUser.stripe.url') + id + '/'
        },
        owner: {
          name: owner
        }
      });
    },
    getSofort: function getSofort(id, amount, description) {
      return window.stripe.createSource({
        type: 'sofort',
        amount: amount,
        currency: 'eur',
        statement_descriptor: description,
        redirect: {
          return_url: '' + this.get('session.currentUser.stripe.url') + id + '/'
        },
        sofort: {
          country: this.get('session.currentUser.stripe.country')
        }
      });
    }
  });
});