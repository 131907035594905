define('levit-intranet/components/user-password/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    layoutName: 'components/user-preferences',
    fieldsets: [{
      fields: [{
        fields: [{
          name: 'password1',
          label: 'New password',
          widget: 'secret',
          required: true
        }, {
          name: 'password2',
          label: 'Password confirmation',
          widget: 'secret',
          required: true
        }],
        innerClass: 'col-md-6',
        widget: 'fieldset'
      }],
      title: null
    }]
  });
});