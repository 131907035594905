define('levit-intranet/application/adapter', ['exports', 'levit-intranet/adapters/drf', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _drf, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:django',
    coalesceFindRequests: true,
    updateRecord: function updateRecord(store, type, snapshot) {
      // if (isEnabled('ds-improved-ajax') && !this._hasCustomizedAjax()) {
      //   const request = this._requestFor({
      //     store, type, snapshot,
      //     requestType: 'updateRecord'
      //   });

      //   return this._makeRequest(request);

      // }
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      var id = snapshot.id;
      if (Object.keys(data).length > 0) {
        var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

        return this.ajax(url, 'PATCH', { data: data });
      }
      return Ember.RSVP.resolve({ id: id });
    }
  });
});