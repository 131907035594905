define('levit-intranet/router', ['exports', 'levit-intranet/config/environment', 'levit-intranet/sections'], function (exports, _environment, _sections) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  exports.default = Router.map(function () {
    var _this = this;

    _sections.default.forEach(function (section) {
      var opts = section.opts || {};
      if (section.route !== 'index') {
        _this.route(section.route, opts);
      }
    });
    this.route('login');
    this.route('logout');
    this.route('_meta', function () {
      this.route('preferences');
      this.route('password');
      this.route('reset');
    });

    this.route('index', { path: '/' }, function () {
      this.route('app', { path: '/:app_name' });
      this.route('list', { path: '/:app_name/:model_name' });
      this.route('form', { path: '/:app_name/:model_name/:id' });
    });
  });
});