define('levit-intranet/models/base/sale/pricelist', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    mult_by: (0, _attr.default)('number'),

    add: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    parties: (0, _relationships.hasMany)('crm/party', {
      async: true,
      inverse: 'pricelist'
    }),

    items: (0, _relationships.hasMany)('sale/pricelistitem', {
      async: true,
      inverse: 'pricelist'
    }),

    parent: (0, _relationships.belongsTo)('sale/pricelist', {
      async: true,
      inverse: null
    })

  });
});