define('levit-intranet/models/base/accounting/paymentterm', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    days: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string')

  });
});