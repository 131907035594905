define('levit-intranet/utils/validation', ['exports', 'ember-cli-crudities/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _validation.default;
    }
  });
});