define('levit-intranet/components/dashboard-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modelLoader: Ember.inject.service(),
    ready: false,
    meta: {},

    init: function init() {
      var _this = this;

      this._super();
      var params = this.get('params');
      if (params.requiresModel && params.component) {
        var model_name = params.requiresModel.split('/', 2);
        var modelLoader = this.get('modelLoader');
        modelLoader.ensure_model(model_name[0], model_name[1]).then(function (meta) {
          _this.set('meta', meta.meta);
          _this.set('ready', true);
        });
      } else if (!params.component) {
        // never ready => don't display anything
      } else {
        this.set('ready', true);
      }
    }
  });
});