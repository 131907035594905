define('levit-intranet/models/base/sale/pricelistitem', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    price: (0, _attr.default)('number'),

    __str__: (0, _attr.default)('string'),

    pricelist: (0, _relationships.belongsTo)('sale/pricelist', {
      async: true,
      inverse: 'items'
    }),

    product: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    })

  });
});