define('levit-intranet/models/base/cities-light/city', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    __str__: (0, _attr.default)('string'),

    postal_codes: (0, _relationships.hasMany)('intranet/postalcode', {
      async: true,
      inverse: 'city'
    })

  });
});