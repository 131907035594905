define('levit-intranet/sections', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    title: 'Home',
    route: 'index',
    opts: {
      path: '/'
    }
  }];
});