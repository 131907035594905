define('levit-intranet/components/is-loading', ['exports', 'ember-cli-crudities/components/is-loading'], function (exports, _isLoading) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isLoading.default;
    }
  });
});