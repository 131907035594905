define('levit-intranet/models/base/sale/product', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    unit_price: (0, _attr.default)('number'),

    sale_type: (0, _attr.default)('string'),

    duration: (0, _attr.default)('string'),

    is_active: (0, _attr.default)('boolean'),

    duration_provision: (0, _attr.default)('duration'),

    round_duration: (0, _attr.default)('duration'),

    min_bill_duration: (0, _attr.default)('duration'),

    auto_refill: (0, _attr.default)('boolean'),

    is_public: (0, _attr.default)('boolean'),

    gen_list_on: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    subscribers: (0, _relationships.hasMany)('sale/subscription', {
      async: true,
      inverse: 'product'
    }),

    fall_back: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: null
    }),

    send_list_to: (0, _relationships.belongsTo)('intranet/user', {
      async: true,
      inverse: null
    }),

    default_taxes: (0, _relationships.hasMany)('accounting/tax', {
      async: true,
      inverse: null
    })

  });
});