define('levit-intranet/models/base/levit-report/document', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),

    slug: (0, _attr.default)('string'),

    source: (0, _attr.default)('string'),

    convert_to: (0, _attr.default)('string'),

    merge_with_tos: (0, _attr.default)('boolean'),

    get_language_from_target: (0, _attr.default)('boolean'),

    is_default_for_ct: (0, _attr.default)('boolean'),

    __str__: (0, _attr.default)('string'),

    content_type: (0, _relationships.belongsTo)('contenttypes/contenttype', {
      async: true,
      inverse: 'reports'
    })

  });
});