define('levit-intranet/models/base/accounting/period', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    label: (0, _attr.default)('string'),

    period_type: (0, _attr.default)('string'),

    start_date: (0, _attr.default)('nullable'),

    end_date: (0, _attr.default)('nullable'),

    state: (0, _attr.default)('string'),

    ci_count: (0, _attr.default)('string'),

    cc_count: (0, _attr.default)('string'),

    si_count: (0, _attr.default)('string'),

    sc_count: (0, _attr.default)('string'),

    att_count: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    attachements: (0, _relationships.hasMany)('intranet/attachement', {
      async: true,
      inverse: null
    })

  });
});