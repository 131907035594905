define('levit-intranet/components/load-messages/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var messages = window.messages;
      window.messages = [];
      if (!Ember.isEmpty(messages)) {
        Ember.run.later(this, function () {
          var flashMessages = _this.get('flashMessages');
          messages.forEach(function (message) {
            if (message.type === 'error') {
              message.type = 'danger';
            }
            flashMessages[message.type](message.value);
          });
        }, 1500);
      }
    }
  });
});