define('levit-intranet/application/serializer', ['exports', 'levit-intranet/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    serialize: function serialize(record, options) {
      var _this = this;

      var json = {};

      if (options && options.includeId) {
        // if (isEnabled('ds-serialize-id')) {
        //   this.serializeId(record, json, Ember.get(this, 'primaryKey'));
        // } else {
        var id = record.id;
        if (id) {
          json[Ember.get(this, 'primaryKey')] = id;
        }
        // }
      }

      var changed = Object.keys(Ember.get(record, 'record').modelChanges());
      var isNew = Ember.get(record, 'record.isNew');
      record.eachAttribute(function (key, attribute) {
        // only serialize attributes which have been changed and
        // arent readonly
        if (isNew || changed.indexOf(key) !== -1) {
          if (!attribute.options.readOnly) {
            _this.serializeAttribute(record, json, key, attribute);
          }
        }
      });

      record.eachRelationship(function (key, relationship) {
        // if a relationship is embedded, remove that option,
        // as we only wanna deserialize embedded objects
        var isEmbedded = _this.get('attrs.' + key + '.embedded');
        if (isEmbedded) {
          _this.set('attrs.' + key + '.embedded', null);
        }

        // only serialize relationships that arent read only
        if (isNew || changed.indexOf(key) !== -1) {
          if (relationship.kind === 'belongsTo') {
            _this.serializeBelongsTo(record, json, relationship);
          } else if (relationship.kind === 'hasMany') {
            _this.serializeHasMany(record, json, relationship);
          }
        }

        // set back the embedded flag for deserializing
        if (isEmbedded) {
          _this.set('attrs.' + key + '.embedded', isEmbedded);
        }
      });
      return json;
    }
  });
});