define('levit-intranet/models/accounting/overdueaction', ['exports', 'levit-intranet/models/base/accounting/overdueaction'], function (exports, _overdueaction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _overdueaction.default.extend({
    close: function close() {
      var _this = this;

      this.set('is_done', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('event').then(function (event) {
          event.set('state', 'closed');
          Ember.RSVP.allSettled([event.save(), _this.save()]).then(resolve, reject);
        }, reject);
      });
    }
  });
});