define('levit-intranet/initializers/debug', ['exports', 'smoke-and-mirrors/-debug'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'smoke-and-mirrors-debug',
    initialize: function initialize() {}
  };
});