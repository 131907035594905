define('levit-intranet/helpers/action-url', ['exports', 'ember-cli-crudities/helpers/action-url'], function (exports, _actionUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _actionUrl.default;
    }
  });
  Object.defineProperty(exports, 'actionUrl', {
    enumerable: true,
    get: function () {
      return _actionUrl.actionUrl;
    }
  });
});