define('levit-intranet/models/base/sale/subscription', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    description: (0, _attr.default)('string'),

    last_invoice_date: (0, _attr.default)('nullable'),

    next_invoice_date: (0, _attr.default)('nullable'),

    expiration_date: (0, _attr.default)('nullable'),

    dont_renew: (0, _attr.default)('boolean'),

    price: (0, _attr.default)('string'),

    expires_in_days: (0, _attr.default)('string'),

    uninvoiced_this_year: (0, _attr.default)('string'),

    is_active: (0, _attr.default)('string'),

    has_domain: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    invoicelines: (0, _relationships.hasMany)('accounting/invoiceline', {
      async: true,
      inverse: 'subscription'
    }),

    domain: (0, _relationships.belongsTo)('hosting/domain', {
      async: true,
      inverse: 'subscription'
    }),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: 'subscriptions'
    }),

    product: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: 'subscribers'
    }),

    next_invoice: (0, _relationships.belongsTo)('accounting/invoice', {
      async: true,
      inverse: null
    })

  });
});