define('levit-intranet/components/form-duration', ['exports', 'ember-cli-crudities/components/form-duration'], function (exports, _formDuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formDuration.default;
    }
  });
});