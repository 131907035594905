define('levit-intranet/models/intranet/user', ['exports', 'levit-intranet/models/base/intranet/user', 'ember-data/relationships'], function (exports, _user, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _user.default.extend({
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    roles: (0, _relationships.hasMany)('intranet/role', {
      async: true,
      inverse: 'members'
    }),

    impersonate: function impersonate() {
      var _this = this;

      var session = this.get('session');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        session.impersonate(_this.get('id')).then(function () {
          _this.get('flashMessages').success('Now impersonating ' + _this.get('username'));
          resolve();
        }, function () {
          _this.get('flashMessages').danger('Unable to impersonate ' + _this.get('username'));
          reject();
        });
      });
    }
  });
});