define('levit-intranet/components/action-wizard', ['exports', 'ember-cli-crudities/components/action-wizard'], function (exports, _actionWizard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _actionWizard.default;
    }
  });
});