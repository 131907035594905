define('levit-intranet/models/base/contenttypes/contenttype', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    app_label: (0, _attr.default)('string'),

    model: (0, _attr.default)('string'),

    plural: (0, _attr.default)('string'),

    __str__: (0, _attr.default)('string'),

    reports: (0, _relationships.hasMany)('levit_report/document', {
      async: true,
      inverse: 'content_type'
    })

  });
});