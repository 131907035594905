define('levit-intranet/components/attachments-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOpen: false,
    classNameBindings: ['dropDown'],

    dropDown: Ember.computed('isOpen', function () {
      var rv = 'dropdown';
      if (this.get('isOpen') === true) {
        rv += ' open';
      }
      return rv;
    }),

    hasAttachments: false,
    onAttachmentsChange: Ember.observer('attachments', 'attachments.length', 'attachments.@each.length', function () {
      var _this = this;

      var length = this.get('attachments.length');
      if (length === 0) {
        this.set('hasAttachments', false);
        return;
      }
      this.get('attachments').then(function (attachments) {
        if (Ember.isEmpty(attachments)) {
          _this.set('hasAttachments', false);
          return;
        }
        _this.set('hasAttachments', attachments.any(function (attachment) {
          var name = attachment.get('name');
          return !Ember.isEmpty(name) && name.indexOf('__cached__') !== 0;
        }));
      });
    })
  });
});