define('levit-intranet/models/base/wizard/hosting/domains/createsubscription', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    description: (0, _attr.default)('string'),

    dont_renew: (0, _attr.default)('boolean'),

    next_invoice_date: (0, _attr.default)('nullable'),

    expiration_date: (0, _attr.default)('nullable'),

    party: (0, _relationships.belongsTo)('crm/party', {
      async: true,
      inverse: null
    }),

    product: (0, _relationships.belongsTo)('sale/product', {
      async: true,
      inverse: null
    })

  });
});