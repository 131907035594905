define('levit-intranet/services/session', ['exports', 'levit-intranet/config/environment', 'ember-simple-auth/services/session', 'levit-intranet/utils/make-request'], function (exports, _environment, _session, _makeRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  exports.default = _session.default.extend({
    currentUser: null,
    loginError: false,

    impersonate: function impersonate(id) {
      var _this = this;

      var globalConfig = _environment.default['ember-simple-auth'] || {};
      var serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
      var url = serverAuthEndpoint + '/impersonate/';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _makeRequest.default)(url, { target: id }).then(function () {
          _this.do_set_current_user();
          resolve();
          window.location = window.location.protocol + '//' + window.location.host;
        }, reject);
      });
    },
    stopImpersonate: function stopImpersonate() {
      var _this2 = this;

      var globalConfig = _environment.default['ember-simple-auth'] || {};
      var serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
      var url = serverAuthEndpoint + '/impersonate/stop/';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _makeRequest.default)(url).then(function () {
          _this2.do_set_current_user();
          resolve();
          window.location = window.location.protocol + '//' + window.location.host;
        }, reject);
      });
    },
    do_set_current_user: function do_set_current_user() {
      var _this3 = this;

      if (this.get('isAuthenticated')) {
        var globalConfig = _environment.default['ember-simple-auth'] || {};
        var serverAuthEndpoint = globalConfig.serverAuthEndpoint || '/rest-auth';
        var url = serverAuthEndpoint + '/me/';
        (0, _makeRequest.default)(url, null, 'GET').then(function (user) {
          _this3.set('currentUser', user);
          window._opbeat && window._opbeat('setUserContext', {
            username: user.username
          });
        }, function () {
          _this3.set('isAuthenticated', false);
        });
      } else {
        this.set('currentUser', '');
      }
    },


    setCurrentUser: observer('isAuthenticated', function () {
      this.do_set_current_user();
    })
  });
});