define('levit-intranet/components/user-timers/component', ['exports', 'ember-cli-crudities/mixins/with-custom-actions', 'ember-cli-crudities/mixins/filterable-actions', 'levit-intranet/mixins/with-socket'], function (exports, _withCustomActions, _filterableActions, _withSocket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_withCustomActions.default, _filterableActions.default, _withSocket.default, {
    modelLoader: Ember.inject.service(),
    store: Ember.inject.service(),

    updated: null,
    customActions: [],

    socketName: 'timers',

    timers: Ember.computed('updated', function () {
      if (!Ember.isEmpty(this.get('updated'))) {
        return this.get('store').findAll('crm/timer');
      }
      return [];
    }),

    messageHandler: function messageHandler(event) {
      var data = JSON.parse(event.data);
      if (data.action !== undefined) {
        if (data.action === 'refresh') {
          this.set('updated', new Date());
        } else if (data.action === 'delete') {
          var model = data.model;
          var store = this.get('store');
          data.ids.forEach(function (id) {
            var record = store.peekRecord(model, id);
            if (!Ember.isEmpty(record) && record.get('isDeleted') !== true) {
              record.deleteRecord();
            }
          });
          this.set('updated', new Date());
        }
      } else {
        this.get('store').pushPayload(data);
        this.set('updated', new Date());
      }
    },
    init: function init() {
      var _this = this;

      this._super();
      this.get('modelLoader').ensure_model('crm', 'timer').then(function (model) {
        _this.set('customActions', Ember.get(model, 'meta.custom_actions'));
        _this.set('updated', new Date());
        // this.connectSocket();
      });
    },
    startTimer: function startTimer(timer) {
      this.get('timers').forEach(function (otherTimer) {
        otherTimer.stop();
      });
      timer.start();
    },


    actions: {
      newTimer: function newTimer() {
        var _this2 = this;

        this.get('timers').forEach(function (timer) {
          timer.stop();
        });
        this.get('store').createRecord('crm/timer', { is_running: true }).save().then(function () {
          _this2.set('updated', new Date());
        });
      }
    }
  });
});