define('levit-intranet/components/shortcut-icon/component', ['exports', 'levit-intranet/mixins/hash-attrs'], function (exports, _hashAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hashAttrs.default, {
    modelLoader: Ember.inject.service(),
    _ready: false,
    ready: Ember.computed.and('app_name', 'model_name', '_ready'),
    route: 'index.list',

    ensureModel: function ensureModel() {
      var _this = this;

      var modelLoader = this.get('modelLoader');
      var modelName = this.get('model_name');
      var appName = this.get('app_name');
      if (modelName && appName) {
        modelLoader.ensure_model(appName, modelName).then(function () {
          if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
            _this.set('_ready', true);
          }
        });
      } else {
        Ember.run.later(this, this.ensureModel.bind(this), 150);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();
      this.ensureModel();
    },
    change: function change() {
      //placeholder
    }
  });
});