define('levit-intranet/models/base/wizard/accounting/invoices/pay', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    amount: (0, _attr.default)('number'),

    date: (0, _attr.default)('nullable'),

    comment: (0, _attr.default)('string'),

    account: (0, _relationships.belongsTo)('accounting/account', {
      async: true,
      inverse: null
    })

  });
});